import React from "react"
import { Seo, Layout } from "../components"
import { StaticImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SVGAudit from '../assets/SEO_Audit.svg'
import SVGStrategie from '../assets/SEO_Strategie.svg'
import SVGSearch from '../assets/SEO_SearchConsole.svg'
import SVGMise from '../assets/SEO_MiseEnPlace.svg'

const ReferencementSiteInternet = () => {
  return (
    <Layout>
      <Seo 
      title="Agence de référencement naturel - SEO Google - Koomet"
      desc="Koomet est une agence de référencement naturel (SEO), améliorez dès aujourd'hui le positionnement de votre site sur Google !"
      breadcrumb
      bread1name="Accueil"
      bread1url="/"
      bread2name="Référencement naturel"
      bread2url="/referencement-naturel-site-internet/"
       />
      <div className="container mx-auto px-6 lg:px-0">
        <div className="relative">
          <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
            <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl lg:right-72" />
            <svg
              className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-indigo-100 dark:text-violet/20" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
            </svg>
          </div>
        </div>
        <div className="relative">
          <div className="inset-x-0 bottom-0 h-1/2 bg-gris" />
          <div data-sal="fade" data-sal-duration="800" data-sal-easing="ease" className="max-w-8xl mx-auto lg:px-20">
            <div className="relative shadow-xl rounded-xl sm:overflow-hidden shadow-indigo-100 dark:shadow-md">
              <div className="absolute inset-0">
                <StaticImage
                  src="../assets/Referencement.webp"
                  quality={100}
                  alt="Notre agence Koomet optimise le référencement naturel de votre site Internet et le place en meilleure position sur Google et autres moteurs de recherche"
                  className="h-full w-full rounded-xl object-cover"
                />
                <div className="absolute inset-0 rounded-xl bg-gradient-to-t bg-violetBright mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                <h1 data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">Agence de référencement naturel (SEO)</h1>
                <p data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="mt-6 text-xl text-white max-w-5xl">
                  Optimisez la visibilité et le positionnement de votre site Internet sur les moteurs de recherche (Google, Bing...) grâce au référencement naturel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative container w-full px-6 lg:px-20 mx-auto text-center pt-8 lg:pt-20">
        <h2 className="text-2xl md:text-5xl font-extrabold text-gray-700 dark:text-gray-200">Qu'est-ce-que le référencement naturel ?</h2>
        <p className="mt-5">Le référencement naturel, de son abréviation: <strong>SEO (Search Engine Optimization = Optimisation pour les moteurs de recherche)</strong> consiste à améliorer le positionnement d'un site Internet sur les moteurs de recherche Google, Bing & autres afin d'en augmenter le trafic.</p>
        <p className="mt-3 font-bold">Les objectifs du référencement naturel d'un site Internet :</p>
        <ul>
          <li>- Faire apparaître votre site Internet sur la première page de résultats de Google en ciblant des mots-clés bien précis.</li>
          <li>- Augmenter le nombre de visites en provenance de Google, Bing... (trafic organique).</li>
          <li>- Augmenter votre chiffre d'affaire grâce à votre présence en ligne.</li>
        </ul>
      </div>
      <div className="relative w-full mx-auto">
        <div className="relative mt-10 mb-10 text-center">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-20 md:px-3 bg-white dark:bg-violetDarken text-2xl md:text-lg font-medium text-gray-900 dark:text-gray-200">Les étapes de notre prestation de référencement naturel</span>
          </div>
        </div>
        <div className="p-5 pt-0">
          <div className="container mx-auto px-4 lg:px-20 relative py-4 lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center p-5">
            <div data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="relative lg:col-start-1 px-16 lg:px-5">
              <SVGStrategie />
            </div>
            <div data-sal="slide-left" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="lg:col-start-2 text-center mt-4 md:mt-0">
              <p className="text-6xl md:text-8xl font-extrabold text-violet dark:text-gray-200">ÉTAPE 1</p>
              <h3 className="text-violet text-3xl font-bold">Audit SEO de votre site Internet</h3>
              <p className="mt-8 text-lg text-gray-500 dark:text-gray-300">
                Nous analysons votre site Internet <strong>(audit on-site)</strong> (Éléments techniques : optimisation de la vitesse, balises meta, title, description, titres de pages...) et son environnement <strong>(audit off-site)</strong> (liens entrants, concurrents, mots clés..).
              </p>
              <p className="mt-4 text-lg text-gray-500 dark:text-gray-300">
                Ces deux audits nous permettent de déterminer les points faibles et les points forts de votre référencement naturel actuel et de relever les améliorations possibles.
              </p>
              <p className="mt-4 text-lg text-gray-500 dark:text-gray-300">
                Grâce à ces dizaines de critères, nous pourrons ensuite définir une stratégie de référencement naturel.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-w-primary/80 p-5">
          <div className="container mx-auto px-4 lg:px-20 relative py-4 lg:mt-18 lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div data-sal="slide-left" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="relative lg:col-start-2 px-16 lg:px-5">
              <SVGAudit />
            </div>
            <div data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="relative text-center mt-4 md:mt-0">
              <p className="text-6xl md:text-8xl font-extrabold text-white inline-block">ÉTAPE 2</p>
              <h3 className="text-white text-3xl font-bold">Étude & Stratégie SEO</h3>
              <p className="mt-8 text-lg text-white">
                Maintenant que nous avons analysé votre site et son environnement, une étude des concurrents et du marché est réalisée pour définir vos personas et votre cible.
              </p>
              <p className="mt-4 text-lg text-white">
                L'étude est terminée, nous nous servons de celle-ci pour rechercher des mots-clés correspondants à vos personas et votre cible.
              </p>
              <p className="mt-4 text-lg text-white">
                La stratégie est créée ! Tout peut être mis en place, ex: construction d'une arborescence utilisant les mots-clés, création de pages et contenus spécifiques pour certains mots-clés, cocon sémantique etc...
              </p>
            </div>
          </div>
        </div>
        <div className="p-5">
          <div className="container mx-auto px-4 lg:px-20 relative py-4 lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center p-5">
            <div data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="relative lg:col-start-1 px-16 lg:px-5">
              <SVGMise />
            </div>
            <div data-sal="slide-left" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="lg:col-start-2 text-center mt-4 md:mt-0">
              <p className="text-6xl md:text-8xl font-extrabold text-violet dark:text-gray-200">ÉTAPE 3</p>
              <h3 className="text-violet text-3xl font-bold">Application de la stratégie SEO</h3>
              <p className="mt-8 text-lg text-gray-500 dark:text-gray-300">
                Les grands travaux commencent ! Nous travaillons d'arrache-pied pour mettre en place la stratégie SEO définie.
              </p>
              <p className="mt-8 text-lg text-gray-500 dark:text-gray-300">
                Nous mettons en place les pages, rédigeons les contenus utilisant les mots-clés, optimisons la vitesse du site Internet, optimisons les balises meta, mise en place du cocon sémantique, etc...
              </p>
            </div>
          </div>
        </div>
        <div className="bg-w-primary/80 p-5">
          <div className="container mx-auto px-4 lg:px-20 relative py-4 lg:mt-18 lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div data-sal="slide-left" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="relative lg:col-start-2 px-16 lg:px-5">
              <SVGSearch />
            </div>
            <div data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="relative text-center mt-4 md:mt-0">
              <p className="text-6xl md:text-8xl font-extrabold text-white">ÉTAPE 4</p>
              <h3 className="text-white text-3xl font-bold">Suivi & reporting</h3>
              <p className="mt-8 text-lg text-white">
                Les travaux sont terminés... Mais, <strong>tout n'est pas terminé</strong> !
              </p>
              <p className="mt-4 text-lg text-white">
                Et oui, les effets du référencement naturel ne sont pas immédiats et nécessitent parfois quelques ajustements sur le long terme. Nous effectuons un suivi on-site & off-site (quotidien ou hebdomadaire) pour déceler d'éventuels problèmes.
              </p>
              <p className="mt-4 text-lg text-white">
                Enfin, notre prestation inclus un rapport mensuel pour suivre les performances du référencement naturel de votre site Internet !
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-gradient-to-r from-indigo-400 via-violet to-indigo-400">
        <div className="max-w-3xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            <span className="block">Vous voulez en savoir plus sur notre prestation de référencement naturel ?</span>
          </h2>
          <p className="mt-4 text-2xl font-medium leading-6 text-indigo-100">
            N'hésitez pas à nous contacter !
          </p>
          <AniLink
            cover bg="#6377E9" direction="up" duration={0.6} to="/contact/"
            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-violet bg-white hover:bg-indigo-50 sm:w-auto transition duration-200 hover:-translate-y-0.5 hover:shadow-md"
          >
            Contactez-nous ! 🤗
          </AniLink>
        </div>
      </div>
    </Layout>
  )
}

export default ReferencementSiteInternet
